import request from '@/utils/request'

//列出所有事件
export function listEvent() {
  return request({
    url: '/events',
    method: 'get'
  })
}

//创建事件
export function createEvent(data) {
  return request({
    url: '/events',
    method: 'post',
    data
  })
}

//删除事件
export function deleteEvent(id) {
  return request({
    url: '/events/' + id,
    method: 'delete'
  })
}

//加入事件
export function registerEvent(data, id) {
  console.log(data, id)
  return request({
    url: `/events/${id}/register`,
    method: 'patch',
    data
  })
}
//退出事件
export function cancelEvent(id) {
  return request({
    url: `/events/${id}/cancel`,
    method: 'patch'
  })
}
