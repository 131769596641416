import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
//完整引用ElmentUI
import ElementUI from 'element-ui'
Vue.config.productionTip = false
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'
//引入头像裁剪插件
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
// 引入partucles.js
import particlesjs from 'particles.js'
// 全局注册echarts
import * as echarts from 'echarts'
/* v-md-editor 编辑器 start  */
/* 1、v-md-editor 基础引用  */
import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'
VueMarkdownEditor.use(vuepressTheme, {
  Prism
})

/* 2、v-md-editor 代码块关键字高亮  */
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import '@kangc/v-md-editor/lib/theme/style/github.css'
// 引入所有语言包
import hljs from 'highlight.js'
VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs
})
Vue.use(VueMarkdownEditor)

/* 3、v-md-editor 二次封装  */
import mdEditor from '@/components/v-md-editor/index'
Vue.component('MdEditor', mdEditor)
/* v-md-editor 编辑器 end  */

Vue.prototype.$echarts = echarts
Vue.use(particlesjs)
new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
