import {
  getAllAssets,
  uploadAssetInfo,
  deleteAssetInfo,
  getAssetjournals,
  uploadAssetjournals
} from '@/api'

// 资产管理
const state = {
  assetList: []
}

const mutations = {
  setAssetList(state, assetList) {
    state.assetList = assetList
  }
}

const actions = {
  async fetchAssetList({ commit, dispatch }) {
    try {
      const assetsRes = await getAllAssets()
      const assetListWithLogs = await Promise.all(
        assetsRes.data.message.map(async (asset) => {
          const journalsRes = await dispatch(
            'getAssetjournals',
            asset.id
          )
          return { ...asset, journals: journalsRes.data.message }
        })
      )
      commit('setAssetList', assetListWithLogs)
    } catch (err) {
      console.error(err)
      throw new Error(err.message)
    }
  },
  // 上传资产
  async uploadAsset({ commit }, payload) {
    let res
    try {
      res = await uploadAssetInfo(payload)
    } catch (err) {
      throw new Error(err.message)
    }
    return res
  },
  // 删除资产
  async deleteAsset({ commit }, id) {
    let res
    try {
      res = await deleteAssetInfo(id)
    } catch (err) {
      throw new Error(err.message)
    }
    return res
  },
  // 上传资产日志
  async uploadAssetjournals({ commit }, { id, data }) {
    let res
    try {
      res = await uploadAssetjournals(id, data)
    } catch (err) {
      throw new Error(err.message)
    }
    return res
  },
  // 获取资产日志
  async getAssetjournals({ commit }, id) {
    let res
    try {
      res = await getAssetjournals(id)
    } catch (err) {
      throw new Error(err.message)
    }
    return res
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
