// userModule.js
let uid = localStorage.getItem('uid')

import { profile, updateAvatar, updateProfile } from '@/api/User'
const state = {
  user: {} // 初始状态为空
}

const mutations = {
  setUser(state, userData) {
    state.user = userData
  },
  setAvatarUrl(state, url) {
    state.avatarUrl = url
  },
  updateUserInfo(state, updatedInfo) {
    // 使用 Object.assign 或类似方式更新用户信息
    state.user = Object.assign({}, state.user, updatedInfo)
  }
}

const actions = {
  async fetchUserInfo({ commit }) {
    try {
      let id = localStorage.getItem('uid')

      let res = await profile(id)
      commit('setUser', res.data)
    } catch (error) {
      let message = error.message
      throw new Error(message)
    }
  },

  // 更新头像信息
  async updataAvatar({ commit }, formData) {
    try {
      const res = await updateAvatar(formData, uid)
      if (res.status === 200) {
        return res // 在这里返回你需要的信息
      }
    } catch (error) {
      throw new Error(error.message)
    }
  },
  // 更新用户信息
  async updataUserInfo({ commit }, updatedInfo) {
    try {
      const res = await updateProfile(updatedInfo, uid)
      if (res.status === 200) {
        commit('updateUserInfo', updatedInfo)
        return res // 在这里返回你需要的信息
      }
    } catch (error) {
      throw new Error(error.message) // 将错误继续抛出，以便在组件中捕获
    }
  }
}

export default {
  namespaced: true, // 命名空间
  state,
  mutations,
  actions
}
