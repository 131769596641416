<!--
详见： https://blog.csdn.net/qq_41463655/article/details/125340036
 -->

<template>
  <div>
    <v-md-editor
      v-model="editorValue"
      @upload-image="handleUploadImage"
      height="680px"
    ></v-md-editor>
  </div>
</template>

<script>
export default {
  name: 'mdEditor',
  data() {
    return {
      editorValue: this.content != null ? this.content : ''
    }
  },
  props: {
    // 接收值父组件传递值
    content: String
  },
  created() {
    console.log('--------加载md编辑器')
  },
  methods: {
    // v-md-editor 文件上传
    handleUploadImage(event, insertImage, files) {
      // console.log(files);
      // 上传
      for (let i = 0; i < files.length; i++) {
        // 创建一个包含所有上传 Promise 的数组
        const uploadPromises = files.map((file) => {
          return this.crud
            .upload(file, 'image/vMdEditor/')
            .then((res) => {
              // 从响应中提取图片 URL 和描述
              let data = res.data.data
              return {
                url: data.url,
                desc: data.name
              }
            })
        })

        // 使用 Promise.all 等待所有上传完成
        Promise.all(uploadPromises).then((uploadResults) => {
          // 上传完成后，遍历结果并插入图片
          uploadResults.forEach((result) => {
            insertImage(result)
          })
        })
      }
    }
  },
  watch: {
    editorValue: function (newNum, oldNum) {
      // 修改调用者传入的值
      this.$emit('update:content', newNum)
    }
  }
}
</script>

<style></style>
