import {
  registerEvent,
  cancelEvent,
  listEvent,
  createEvent,
  deleteEvent
} from '@/api/Event'
// 事件管理
const state = {
  eventList: []
}

const mutations = {
  setEventList(state, eventList) {
    state.eventList = eventList
  }
}

const actions = {
  async fetchEventList({ commit }) {
    let eventList = []
    try {
      const res = await listEvent()
      eventList = res.data.message
      commit('setEventList', eventList)
    } catch (err) {
      throw new Error(err.message)
    }
  },
  // 添加事件
  async addEvent({ dispatch }, event) {
    try {
      await createEvent(event)
      dispatch('fetchEventList')
    } catch (err) {
      throw new Error(err.message)
    }
  },
  // 删除事件
  async deleteEvent({ dispatch }, eventId) {
    try {
      await deleteEvent(eventId)
      dispatch('fetchEventList')
    } catch (err) {
      throw new Error(err.message)
    }
  },
  //预约事件
  async reserveEvent({ dispatch }, submitInfo) {
    try {
      console.log(submitInfo)
      await registerEvent(
        submitInfo.reservationForm,
        submitInfo.id
      )
      dispatch('fetchEventList')
    } catch (err) {
      throw new Error(err.message)
    }
  },
  //取消预约
  async cancelEvent({ dispatch }, eventId) {
    try {
      await cancelEvent(eventId)
      dispatch('fetchEventList')
    } catch (err) {
      throw new Error(err.message)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
