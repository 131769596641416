import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/tokens',
    method: 'post',
    data
  })
}
//注册
export function register(data) {
  return request({
    url: '/users',
    method: 'post',
    data
  })
}

//注销
export function unregister(data, uid) {
  return request({
    url: '/users/' + uid,
    method: 'delete',
    data
  })
}

//列出所有用户
export function list() {
  return request({
    url: '/users',
    method: 'get'
  })
}
//获取个人信息
export function profile(uid) {
  return request({
    url: '/users/' + uid,
    method: 'get'
  })
}
//修改个人信息
export function updateProfile(data, uid) {
  return request({
    url: `/users/${uid}`,
    method: 'put',
    data
  })
}
//修改用户密码
export function password(data, uid) {
  return request({
    url: `/users/${uid}/password`,
    method: 'put',
    data
  })
}
// 更新头像
export function updateAvatar(data, uid) {
  return request({
    url: `/users/${uid}/avatar`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
