import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import request from '@/utils/request'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/loginPage.vue')
  },
  {
    path: '/',
    redirect: '/welcome',
    name: 'Home',
    component: () => import('../views/mainPage.vue'),
    children: [
      {
        path: '/welcome',
        name: 'Welcome',
        component: () => import('../views/welcomePage.vue')
      },
      {
        path: '/article',
        name: 'Article',
        component: () =>
          import('../views/article/articlePage.vue')
      },
      {
        path: '/articlePunish',
        name: 'ArticlePunish',
        component: () =>
          import('../views/article/articlePunish.vue')
      },
      {
        path: '/article/:id',
        name: 'AritcleRead',
        component: () =>
          import('../views/article/articleRead.vue')
      },
      {
        path: '/donation',
        name: 'donation',
        component: () => import('../views/donationPage.vue')
      },
      {
        path: '/test',
        name: 'other1',
        component: () => import('../views/otherPage.vue')
      },
      {
        path: '/user',
        name: 'User',
        component: () => import('../views/userPage.vue')
      },
      {
        path: '/service',
        name: 'Service',
        component: () => import('../views/servicePage.vue'),
        children: [
          {
            path: '/service/printer',
            name: 'servicePrinter',
            component: () =>
              import('../views/serviceRelate/printerPage.vue')
          },
          {
            path: '/service/equipment',
            name: 'serviceEquipment',
            component: () =>
              import('../views/serviceRelate/equipmentPage.vue')
          },
          {
            path: '/service/task',
            name: 'Task',
            component: () =>
              import('../views/taskUpload/taskPage.vue')
          },

          {
            path: '/service/taskUpload/:id',
            name: 'TaskUpload',
            component: () =>
              import('../views/taskUpload/taskUpload.vue')
          },
          {
            path: '/service/seat',
            name: 'seat',
            component: () => import('../views/seatPage.vue')
          }
        ]
      },
      {
        path: '/admin',
        name: 'Admin',
        redirect: '/admin/userList',
        component: () => import('../views/adminPage.vue'),
        meta: {
          requiresAdmin: true
        },
        children: [
          {
            path: '/admin/userList',
            name: 'UserManage',
            component: () =>
              import('../views/adminRelate/userList.vue')
          },
          {
            path: '/admin/seatManagement',
            name: 'SeatManagement',
            component: () =>
              import('../views/seatManagement.vue')
          },
          {
            path: '/admin/userAdd',
            name: 'UserAdd',
            component: () =>
              import('../views/adminRelate/userAdd.vue')
          },
          {
            path: '/admin/userActivity',
            name: 'UserActivity',
            component: () =>
              import('../views/adminRelate/userActivity.vue'),
            children: [
              {
                path: '/admin/userInputData',
                name: 'userInputData',
                component: () =>
                  import(
                    '../views/adminRelate/userInputData.vue'
                  )
              },
              {
                path: '/admin/userInputActivity',
                name: 'userInputActivity',
                component: () =>
                  import(
                    '../views/adminRelate/userInputActivity.vue'
                  )
              },
              {
                path: '/admin/userTableData',
                name: 'userTableData',
                component: () =>
                  import(
                    '../views/adminRelate/userTableData.vue'
                  )
              }
            ]
          },
          {
            path: '/admin/sendMail',
            name: 'SendMail',
            component: () =>
              import('../views/adminRelate/emailSend.vue')
          },
          {
            path: '/admin/event',
            name: 'Event',
            component: () =>
              import('../views/adminRelate/eventPage.vue')
          },
          {
            path: '/admin/assets',
            name: 'Assets',
            component: () =>
              import(
                '../views/adminRelate/assets/assetsPage.vue'
              )
          },
          {
            path: '/admin/asset/add',
            name: 'AssetsAdd',
            component: () =>
              import('../views/adminRelate/assets/assetsAdd.vue')
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//路由拦截器
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token')
  if (!token && to.path !== '/login') {
    next('/login')
  } else {
    const role = store.state.userModule.user.role
    if (role == 'ADMIN' || !to.path.startsWith('/admin')) {
      next()
    } else if (to.path.startsWith('/admin')) {
      this.$message.error('您没有权限访问')
      next('/welcome')
    }
  }
})
//全局前置路由守卫，判断用户权限以是否进入管理员页面

export default router
