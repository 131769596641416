// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './modules/userModule'
import userListModule from './modules/userListModule'
import seatsInfoModule from './modules/seatsInfoModule'
import eventModule from './modules/eventModule'
import createPersistedState from 'vuex-persistedstate'
import assetsModule from './modules/assetsModule'
Vue.use(Vuex)

const persistOptions = {
  key: 'vuex',
  paths: [
    'userModule',
    'userListModule',
    'seatsInfoModule',
    'eventModule'
  ]
}

export default new Vuex.Store({
  modules: {
    userModule,
    userListModule,
    seatsInfoModule,
    eventModule,
    assetsModule
  },
  //持久化插件
  plugins: [createPersistedState(persistOptions)]
})
