// store/modules/userModule.js
import { list } from '@/api'
const state = {
  userList: []
}

const mutations = {
  setUserList(state, userList) {
    state.userList = userList
  }
}

const actions = {
  async fetchUserList({ commit }) {
    let userList = []
    try {
      const res = await list()
      userList = res.data
      commit('setUserList', userList)
    } catch (err) {
      throw new Error(err.message)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
