import request from '@/utils/request'
// 返回所有资产信息
export function getAllAssets() {
  return request({
    url: '/assets',
    method: 'get'
  })
}
//上传资产信息
export function uploadAssetInfo(data) {
  return request({
    url: '/assets',
    method: 'post',
    data
  })
}
//删除资产信息
export function deleteAssetInfo(id) {
  return request({
    url: `/assets/${id}`,
    method: 'delete'
  })
}
//获取资产日志
export function getAssetjournals(id) {
  return request({
    url: `/assets/${id}/journals`,
    method: 'get'
  })
}
//上传资产日志
export function uploadAssetjournals(id, data) {
  return request({
    url: `/assets/${id}/journals`,
    method: 'post',
    data
  })
}
