import request from '@/utils/request'
//列出所有座位
export function listSeat() {
  return request({
    url: '/seats',
    method: 'get'
  })
}

//认领座位
export function claimSeat(index) {
  return request({
    url: `/seats/${index}/claim`,
    method: 'patch'
  })
}

// 取消座位认领
export function unclaimSeat(index) {
  return request({
    url: `/seats/${index}/unclaim`,
    method: 'patch'
  })
}

// 管理员设置座位
export function setSeat(index, data) {
  return request({
    url: `/seats/${index}`,
    method: 'put',
    data
  })
}
